import { graphql } from 'gatsby'
import React from 'react'
import { IndexPageQuery } from '/types/graphql-types'
import type { HeadProps } from 'gatsby'
import SEO from '~/components/seo'
import Layout from '~/components/layout'
import ArticleCard from '~/components/article-card'
import { css } from '@emotion/react'
import { ARTICLE_STYLE, PAGE_HEADING_STYLE } from '~/styles/common'

export const pageQuery = graphql`
  query IndexPage {
    allContentfulArticle(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          contentful_id
          title
          slug
          description
          featureImage {
            secure_url
            height
            width
          }
          publishedAt(formatString: "YYYY年M月D日")
          tags {
            title
          }
        }
      }
    }
  }
`

interface Props {
  data: IndexPageQuery
}

const IndexPage: React.FC<Props> = ({ data }) => {
  const { edges } = data.allContentfulArticle

  return (
    <Layout>
      <h2 css={style.heading}>記事一覧</h2>
      <div css={style.wrapper}>
        {edges.map((edge, idx) => {
          const { title, slug, description, featureImage, publishedAt, tags } =
            edge.node
          const thumbnail = featureImage![0]!
          const tagValues = tags!.map((tag) => tag!.title!)

          return (
            <ArticleCard
              key={idx}
              title={title!}
              slug={slug!}
              description={description!}
              publishedAt={publishedAt!}
              tags={tagValues}
              thumbnail={{
                url: thumbnail.secure_url!,
                width: thumbnail.width!,
                height: thumbnail.height!,
              }}
            />
          )
        })}
      </div>
    </Layout>
  )
}

const style = {
  heading: css`
    ${PAGE_HEADING_STYLE}
  `,
  wrapper: css`
    ${ARTICLE_STYLE}
  `,
}

export default IndexPage

export const Head: React.FC<HeadProps> = () => {
  return <SEO />
}
